import { Controller } from '@hotwired/stimulus'
import { scrollTo } from '../helpers/WindowHelper'

export default class extends Controller {
  static values = {
    behavior: String
  }

  connect() {
    this.links.forEach(link => link.addEventListener('click', this.scroll.bind(this)))
  }

  scroll(event) {
    event.preventDefault()
    let elementId = event.currentTarget.getAttribute('href')

    if (elementId.length > 0 && elementId[0] === '#') {
      // we need to remove the first # if it's starting with #
      elementId = elementId.substring(1)
    }

    const element = document.getElementById(elementId)

    if (element) {
      scrollTo(element, this.behaviorValue)
    }
  }

  disconnect() {
    this.links.removeEventListener('click')
  }

  get links() {
    return this.element.querySelectorAll('a[href^="#"]')
  }
}
